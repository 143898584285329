<template>
  <b-modal :visible="show" :hide-header="true" centered id="modal-orientacao-franquia" size="md"
    :hide-footer="true">
    <div class="float-right"><img @click="hide" class="close-modal" src="img/x-modal.svg" /></div>
    <div class="text-justify mt-3">
      <h5 class="bold-modal">Informações da franquia</h5>
      <div class="mb-2">
        A franquia é de 10% do valor dos prejuízos e possui um valor mínimo
        que depende da bike:
      </div>

      <div v-if="valorBike <= 3000">
        Bikes: <strong> R$ 1.000,00 a R$ 3.000,00 o valor mínimo é de R$ 250,00</strong>
      </div>

      <div v-else-if="valorBike > 3000 && valorBike < 6000">
        Bikes: <strong>R$ 1.500,01 a R$ 5.999,99 o valor mínimo é de R$ 500,00</strong>
      </div>

      <div v-else>
        Bikes: <strong>R$ 6.000,00 a R$ 50.000,00 o valor mínimo é o de R$ 800,00 e
          o máximo R$ 3.000.</strong>
      </div>
      <div class="mt-2">
        No nosso Seguro a franquia é descontada direto da indenização pra
        facilitar e agilizar o processo.
      </div>
      <div class="center-modal mt-5">
        <button type="button" class="continue-btn-modal" @click="hide"> Entendi </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: ["show", "valorBike"],
  methods: {
    hide() {
      this.$emit("hide");
    },
  },
};
</script>
<style>
#modal-orientacao-franquia___BV_modal_content_ {
  border-radius: 12px !important;
  padding: 25px;
  padding-top: 15px;
  color: #78849E;
}
.bold-modal{
  font-weight: 700;
  color: #425994;
}

.close-modal {
  width: 15px;
  cursor: pointer;
}

strong {
  font-weight: 600;
  color: #425994;
}

.center-modal {
  text-align: center;
}

.continue-btn-modal {
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border: 1px solid #4FA935;
  border-radius: 8px;
  background: #4FA935;
  color: #fff;
  box-shadow: 9px 12px 30px 0px rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
</style>
